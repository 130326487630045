<template>
  <v-row>
    <!-- kitchen sink -->
    <v-col cols="12">
      <v-card>
        <v-card-title>{{ `${$t('menu.nomenclador')} / ${$t('menu.cars')} / ${$t('menu.oficinaRenta')}` }}</v-card-title>
        <v-card-text>
          <OfficesRentList />
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>

import OfficesRentList from './form/OfficesRentList.vue'

export default {
  components: {
    OfficesRentList,
  },
}
</script>
